import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import InvestInfo from "./InvestInfo";
import Spacing from "../../Spacing";
import { pageTitle } from "../../../helpers/PageTitle";

export default function Invest({ darkMode }) {
  const { projectId } = useParams(); // Get projectId from URL
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `https://getcrowdfundprojectdetails-qj6yfdqbiq-uc.a.run.app?projectId=${projectId}`
        );
        const data = await response.json();
        setProjectData(data);
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    if (projectId) {
      fetchProjectDetails();
    }
  }, [projectId]);

  pageTitle("Project Details");

  if (!projectData)
    return (
      <>
        <Spacing lg="100" md="80" />
        <Spacing lg="100" md="80" />
        <Spacing lg="100" md="80" />
        <Spacing lg="100" md="80" />
        <Spacing lg="100" md="80" />
        <Spacing lg="100" md="80" />
        <Spacing lg="100" md="80" />
        <Spacing lg="100" md="80" />
      </>
    );
  else {
    return (
      <>
        <Spacing lg="100" md="80" />
        <InvestInfo ProjectData={projectData.project} />
        <Spacing lg="125" md="70" />
      </>
    );
  }
}
