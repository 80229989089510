import React, { useEffect, useState } from "react";
import {
  Container,
  FormContainer,
  FormTitle,
  FormGroup,
  Label,
  Select,
  InputGroup,
  InputPrefix,
  Input,
  InputNumberShares,
  QuantityButton,
  CheckboxContainer,
  SubmitButton,
  TermsLink,
} from "./CreateOrder.styles";

const CreateOrder = () => {
  const [buyForm, setBuyForm] = useState({
    group: "",
    sharePrice: null,
    numberOfShares: null,
    totalPrice: null,
    agreed: false,
  });

  const [sellForm, setSellForm] = useState({
    group: "",
    sharePrice: null,
    numberOfShares: null,
    totalPrice: null,
    agreed: false,
  });

  const [projects, setProjects] = useState([{ id: "", title: "Select Group" }]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          "https://listcrowdfundprojects-qj6yfdqbiq-uc.a.run.app"
        );
        const data = await response.json();
        if (data && data.projects && Array.isArray(data.projects)) {
          const simplifiedProjects = data.projects.map(({ id, title }) => ({
            id,
            title,
          }));
          setProjects([
            { id: "", title: "Select Group" },
            ...simplifiedProjects,
          ]);
        } else {
          console.error("Invalid API response format:", data);
          setProjects([{ id: "", title: "Select Group" }]);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  // Handle buy form submission
  const handleBuy = (e) => {
    e.preventDefault();
  };

  // Handle sell form submission
  const handleSell = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setBuyForm({
      ...buyForm,
      totalPrice: Number(
        (buyForm.sharePrice * buyForm.numberOfShares).toFixed(2)
      ),
    });
    setSellForm({
      ...sellForm,
      totalPrice: Number(
        (sellForm.sharePrice * sellForm.numberOfShares).toFixed(2)
      ),
    });
  }, [
    buyForm.sharePrice,
    buyForm.numberOfShares,
    sellForm.sharePrice,
    sellForm.numberOfShares,
  ]);

  return (
    <Container>
      <div className="row g-2">
        <div className="col-md-5">
          <FormContainer>
            <FormTitle>I Want to Buy</FormTitle>
            <form>
              <FormGroup>
                <Label>Group</Label>
                <Select
                  value={buyForm.group}
                  onChange={(e) =>
                    setBuyForm({ ...buyForm, group: e.target.value })
                  }
                >
                  {projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.title}
                    </option>
                  ))}
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>Share Price</Label>
                <InputGroup>
                  <InputPrefix>$</InputPrefix>
                  <Input
                    type="number"
                    placeholder="0.00"
                    hasPrefix
                    value={buyForm.sharePrice}
                    onChange={(e) =>
                      setBuyForm({
                        ...buyForm,
                        sharePrice: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <Label>Number of Shares</Label>
                <InputGroup>
                  <QuantityButton
                    type="button"
                    onClick={() =>
                      setBuyForm({
                        ...buyForm,
                        numberOfShares: buyForm.numberOfShares - 1,
                      })
                    }
                  >
                    -
                  </QuantityButton>
                  <InputNumberShares
                    type="number"
                    value={buyForm.numberOfShares}
                    onChange={(e) =>
                      setBuyForm({
                        ...buyForm,
                        numberOfShares: parseInt(e.target.value) || 0,
                      })
                    }
                    style={{ textAlign: "center" }}
                  />
                  <QuantityButton
                    type="button"
                    onClick={() =>
                      setBuyForm({
                        ...buyForm,
                        numberOfShares: buyForm.numberOfShares + 1,
                      })
                    }
                  >
                    +
                  </QuantityButton>
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <Label>Total Price</Label>
                <InputGroup>
                  <InputPrefix>$</InputPrefix>
                  <Input
                    hasPrefix
                    type="number"
                    placeholder="0.00"
                    value={buyForm.totalPrice}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>

              <CheckboxContainer>
                <Label htmlFor="useBalanceBuy">
                  <input
                    style={{
                      marginRight: "0.5rem",
                      accentColor: "#bf9a5d",
                    }}
                    type="checkbox"
                    id="useBalanceBuy"
                    onChange={(e) =>
                      setBuyForm({ ...buyForm, agreed: e.target.checked })
                    }
                  />
                  I agree with the <TermsLink>terms and conditions</TermsLink>
                </Label>
              </CheckboxContainer>

              <SubmitButton type="submit" variant="buy" onClick={handleBuy}>
                BUY
              </SubmitButton>
            </form>
          </FormContainer>
        </div>

        <div className="col-md-2"></div>

        <div className="col-md-5">
          <FormContainer>
            <FormTitle>I Want to Sell</FormTitle>
            <form>
              <FormGroup>
                <Label>Group</Label>
                <Select
                  value={sellForm.group}
                  onChange={(e) =>
                    setSellForm({ ...sellForm, group: e.target.value })
                  }
                >
                  <option value="">Select Group</option>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>Share Price</Label>
                <InputGroup>
                  <InputPrefix>$</InputPrefix>
                  <Input
                    type="number"
                    placeholder="0.00"
                    hasPrefix
                    value={sellForm.sharePrice}
                    onChange={(e) =>
                      setSellForm({
                        ...sellForm,
                        sharePrice: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <Label>Number of Shares</Label>
                <InputGroup>
                  <QuantityButton
                    type="button"
                    onClick={() =>
                      setSellForm({
                        ...sellForm,
                        numberOfShares: sellForm.numberOfShares - 1,
                      })
                    }
                  >
                    -
                  </QuantityButton>
                  <InputNumberShares
                    type="number"
                    value={sellForm.numberOfShares}
                    onChange={(e) =>
                      setSellForm({
                        ...sellForm,
                        numberOfShares: parseInt(e.target.value) || 0,
                      })
                    }
                    style={{ textAlign: "center" }}
                  />
                  <QuantityButton
                    type="button"
                    onClick={() =>
                      setSellForm({
                        ...sellForm,
                        numberOfShares: sellForm.numberOfShares + 1,
                      })
                    }
                  >
                    +
                  </QuantityButton>
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <Label>Total Price</Label>
                <InputGroup>
                  <InputPrefix>$</InputPrefix>
                  <Input
                    hasPrefix
                    type="number"
                    placeholder="0.00"
                    value={sellForm.totalPrice}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>

              <CheckboxContainer>
                <Label htmlFor="useBalanceSell">
                  <input
                    style={{
                      marginRight: "0.5rem",
                      accentColor: "#bf9a5d",
                    }}
                    type="checkbox"
                    id="useBalanceSell"
                    onChange={(e) =>
                      setSellForm({ ...sellForm, agreed: e.target.checked })
                    }
                  />
                  I agree with the <TermsLink>terms and conditions</TermsLink>
                </Label>
              </CheckboxContainer>

              <SubmitButton type="submit" variant="sell" onClick={handleSell}>
                SELL
              </SubmitButton>
            </form>
          </FormContainer>
        </div>
      </div>
    </Container>
  );
};

export default CreateOrder;
