import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import toast from "react-hot-toast";
import { FaClock } from 'react-icons/fa';
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { fetchCryptoPricesBinance, UserContext } from "../../context/UserContext";
import LoaderComponent from "../Loader/Loader";
import { useTranslation } from "../../hooks/useTranslation";

export default function SellBitcoinModal({ close }) {
  const [stage, setStage] = useState("select");
  const [loading, setLoading] = useState(false);
  const { userBalance } = useContext(UserContext);
  const [cryptoPrices, setCryptoPrices] = useState({
    btc: 0,
    usdt: 0,
  });
  const [countdown, setCountdown] = useState(15);
  
  const [btcAmount, setBtcAmount] = useState(0);
  const [brlAmount, setBrlAmount] = useState(0);
  const [transactionData, setTransactionData] = useState(null);
  const { translate } = useTranslation();

  const btcPriceInBRL = cryptoPrices.btc;

  const handleBtcAmountChange = (e) => {
    const value = parseFloat(e.target.value);

    setBtcAmount(value || 0);

    if (value) {
      const calculatedBrl = (value / 100000000) * btcPriceInBRL;
      setBrlAmount(calculatedBrl);
    } else {
      setBrlAmount(0);
    }
  };

  const handleConfirm = () => {
    if (!btcAmount) {
      toast.error(translate("sellBitcoinModal.error.invalidAmount"));
      return;
    }

    const totalValue = Number(btcAmount);
    
    if (totalValue < 10) {
      toast.error(translate("sellBitcoinModal.error.minimumAmount"));
      return;
    }

    if (totalValue > (userBalance?.btc || 0) * 100000000) {
      toast.error(translate("sellBitcoinModal.error.insufficientBalance"));
      return;
    }

    setStage("confirm");
  };

  const handleFinalConfirm = async () => {
    setLoading(true);

    try {
      const app = getApp();
      const auth = getAuth(app);
      const user = auth.currentUser;

      if (!user) {
        throw new Error(translate("sellBitcoinModal.error.notAuthenticated"));
      }

      const jwtToken = await user.getIdToken();
      const response = await fetch("https://usersellcrypto-qj6yfdqbiq-uc.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          amount: btcAmount / 100000000,
        }),
      });

      if (!response.ok) {
        throw new Error(translate("sellBitcoinModal.error.apiError"));
      }

      const data = await response.json();
      setTransactionData(data);
      toast.success(translate("sellBitcoinModal.successMessage", { btcAmount: (btcAmount / 100000000).toFixed(8) }));
      setStage("BRL3");
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
      toast.error(translate("sellBitcoinModal.error.apiError"));
    } finally {
      setLoading(false);
    }
  };

  const fetchCryptoPrices = async () => {
    try {
      const data = await fetchCryptoPricesBinance();
      setCryptoPrices({
        btc: data.BTCBRL,
        usdt: data.USDTBRL,
      });

      if (brlAmount === 0) {
        setBrlAmount((userBalance?.btc || 0) * data.btc.price);
      }

      return {
        btc: data.btc.price,
        usdt: data.usdt.price,
      }
    } catch (error) {
      console.error("Error fetching crypto prices:", error);
      return {
        btc: 0,
        usdt: 0,
      };
    }
  };

  useEffect(() => {
    fetchCryptoPrices();
  }, []);

  const calculationAmount = async (value) => {
    const cryptoPricesUpdated = await fetchCryptoPrices();
    if (cryptoPricesUpdated?.btc) {
      setBrlAmount((value/100000000) * cryptoPricesUpdated.btc);
    }
  }

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setCountdown(15);
    }

    if (countdown === 15) {
      calculationAmount(btcAmount);
    }

    return () => clearTimeout(timer);
  }, [countdown]);

  return (
    <>
      <Overlay onClick={() => close(true)} />
      <Popup>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "30px",
            zIndex: "1000",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={() => close(true)}
        >
          X
        </div>

        {stage === "select" && (
          <>
            <Header style={{ textAlign: "center" }}>{translate("sellBitcoinModal.title")}</Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>
            <SubHeader style={{ textAlign: "center" }}>{translate("sellBitcoinModal.btcAmountLabel")}</SubHeader>
            <Input
              type="number"
              placeholder="Quantidade de BTC"
              value={btcAmount}
              onChange={handleBtcAmountChange}
              min="1000"
              step="1"
              max={userBalance.btc}
            />
            <SubHeader style={{ textAlign: "center" }}>
                {translate("sellBitcoinModal.confirmSaleTextPart1")}
                <p>₿ { (btcAmount / 100000000).toFixed(8) } {translate("sellBitcoinModal.confirmSaleTextPart2").replace("{brlAmount}", brlAmount.toFixed(2))}</p>
            </SubHeader>

            <SubHeader style={{ textAlign: "center", color: "#ffcc00", fontSize: 16 }}>
              {translate("sellBitcoinModal.exchangeRate").replace("{btcPriceInBRL}", parseFloat(btcPriceInBRL).toFixed(2))}
            </SubHeader>

            {countdown > 0 && btcAmount !== "" && (
              <SubHeader style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", fontSize: 14 }}>
                <FaClock style={{ marginRight: "8px" }} />
                {translate("sellBitcoinModal.countdownText").replace("{countdown}", countdown)}
              </SubHeader>
            )}

            <Button onClick={handleConfirm} style={{ backgroundColor: "#00C160", marginTop: 0}}>{translate("sellBitcoinModal.buttons.next")}</Button>
            <TermsText style={{ marginTop: "0.5rem" }}>
              <TermsLink onClick={() => window.open('/terms-and-conditions', '_blank')}>
                Termos e Condições {" "}
              </TermsLink>
              aplicaveis a esta venda.
            </TermsText>
          </>
        )}

        {stage === "confirm" && (
          <>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <LoaderComponent />
              </div>
            ) : (
              <>
                <Header style={{ textAlign: "center" }}>{translate("sellBitcoinModal.confirmHeader")}</Header>
                <div
                  style={{
                    width: "100%",
                    height: "5px",
                    backgroundColor: "#c09c5c",
                    marginBottom: "20px",
                  }}
                ></div>
                <SubHeader style={{ textAlign: "center" }}>
                  {translate("sellBitcoinModal.confirmSaleTextPart1")}
                  <p>₿ { (btcAmount / 100000000).toFixed(8) } {translate("sellBitcoinModal.confirmSaleTextPart2").replace("{brlAmount}", brlAmount.toFixed(2))}</p>
                </SubHeader>
                
                <SubHeader style={{ textAlign: "center", color: "#ffcc00", fontSize: 16 }}>
                {translate("sellBitcoinModal.exchangeRate").replace("{btcPriceInBRL}", parseFloat(btcPriceInBRL).toFixed(2))}
                </SubHeader>
                {countdown > 0 && btcAmount !== "" && (
                  <SubHeader style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", fontSize: 14 }}>
                    <FaClock style={{ marginRight: "8px" }} />
                    {translate("sellBitcoinModal.countdownText").replace("{countdown}", countdown)}
                  </SubHeader>
                )}
                <Button onClick={handleFinalConfirm} style={{ backgroundColor: "#26A17B", marginTop: 12 }}>{translate("sellBitcoinModal.buttons.confirm")}</Button>
                <Button style={{ backgroundColor: 'red', margin: 0 }} onClick={() => setStage("select")}>{translate("sellBitcoinModal.buttons.cancel")}</Button>

                <TermsText style={{ marginTop: "0.5rem" }}>
                  <TermsLink onClick={() => window.open('/terms-and-conditions', '_blank')}>
                    Termos e Condições {" "}
                  </TermsLink>
                  aplicáveis a esta venda.
                </TermsText>
              </>
            )}
          </>
        )}

        {stage === "BRL3" && (
          <>
            <Header style={{ textAlign: "center" }}>
              {transactionData && transactionData.success ? (
                <>{translate("sellBitcoinModal.successHeader")}</>
              ) : (
                <>{translate("sellBitcoinModal.errorHeader")}</>
              )}
            </Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>
            <SubHeader style={{ textAlign: "center" }}>
              {transactionData && transactionData.success ? (
                <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Flag 
                    src={"/brazil-icon.png"} 
                    alt="Icon do Bitcoin" 
                    style={{ width: "80px", height: "80px" }}
                  />
                  <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {translate("sellBitcoinModal.transactionDetails1")}
                  <p>₿ { (btcAmount / 100000000).toFixed(8) } {translate("sellBitcoinModal.transactionDetails2").replace("{brlAmount}", brlAmount.toFixed(2))}</p>
                  </p>

                  <SubHeader style={{ textAlign: "center", color: "#ffcc00", fontSize: 16 }}>
                    {translate("sellBitcoinModal.exchangeRate").replace("{btcPriceInBRL}", parseFloat(btcPriceInBRL).toFixed(2))}
                  </SubHeader>
                  <p style={{ textAlign: "center", fontSize: 12, marginTop: "10px", marginBottom: "-10px", color: "#ff0000" }}>
                   {translate("sellBitcoinModal.balanceAllocatedMessage")}
                  </p>
                </div>
              ) : (
                <strong>{translate("sellBitcoinModal.transactionDetailsUnavailable")}</strong>
              )}
            </SubHeader>
            <Button onClick={close} style={{ marginTop: 0 }}>{translate("sellBitcoinModal.buttons.ok")}</Button>
          </>
        )}
      </Popup>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 450px;
  background-color: #121212;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;

  @media (max-width: 500px) {
    width: 90%;
    height: 70%;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #ffffff;
`;

const SubHeader = styled.h4`
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #c09c5c;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;

  &:hover {
    background-color: #8c7444;
  }
`; 
const Flag = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const TermsText = styled.p`
  font-size: 0.9rem;
  color: #fff;
  margin-top: 0px;
  text-align: center;
  margin-bottom: -20px;
`;

const TermsLink = styled.span`
  color: #2c65ff;
  cursor: pointer;
`;