import { createContext, useState } from "react";

export const UserContext = createContext();

export async function fetchCryptoPricesBinance() {
  const symbols = ['BTCUSDT', "BTCBRL", "USDTBRL"];
    const [btcUsdtData, btcBrlData, usdtBrlData] = await Promise.all(symbols.map(async (symbol) => {
      try {
        const response = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`);
        if (!response?.ok) {
          console.error(`Failed to fetch price for ${symbol} ERROR: ${response?.status}`);
          return { price: 0 };
        }

      return response.json();
    } catch (error) {
      console.error(`Failed to fetch price for ${symbol} ERROR: ${error}`);
      return { price: 0 };
    }
  }));

  return {
    BTCUSDT: btcUsdtData?.price || 0,
    BTCBRL: btcBrlData?.price || 0,
    USDTBRL: usdtBrlData?.price || 0,
  };
}

export function UserContextProvider({ children }) {
  const [userBalance, setUserBalance] = useState({
    brl: 0,
    btc: 0,
    usdt: 0,
    shares: 0,
    pending: 0,
  });
  const [isLogged, setIsLogged] = useState(false);
  const [accessLevel, setAccessLevel] = useState(0);
  const [cvmApproved, setCVMApproved] = useState(0);
  const [updateHeader, setUpdateHeader] = useState(0);
  const [profilePicture, setProfilePicture] = useState(
    process.env.PUBLIC_URL + "/perfil.png"
  );
  const [cryptoPrices, setCryptoPrices] = useState({
    btc: 0,
    usdt: 0,
  });

  const updateUserBalance = (newBalance) => {
    setUserBalance((prev) => ({
      ...prev,
      ...newBalance,
    }));
  };

  const updateLoginStatus = (status) => {
    setIsLogged(status);
  };

  const updateCryptoPrices = (prices) => {
    setCryptoPrices(prices);
  };

  return (
    <UserContext.Provider
      value={{
        userBalance,
        updateUserBalance,
        isLogged,
        updateLoginStatus,
        setAccessLevel,
        accessLevel,
        setUpdateHeader,
        updateHeader,
        profilePicture,
        setProfilePicture,
        cryptoPrices,
        updateCryptoPrices,
        setCVMApproved,
        cvmApproved,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
