import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import styled from "styled-components";

import "./Portfolio.css";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import Spacing from "../../Spacing";
import LoaderComponent from "../../Loader/Loader";
import StakeShareModal from "../../Modals/StakeShareModal";
import { useTranslation } from "../../../hooks/useTranslation";

const Button = styled.button`
  padding: 10px;
  background-color: #c19a5b;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;
  min-width: 150px;

  &:hover {
    background-color: #8c7444;
  }
`;

const Portfolio = ({ darkMode }) => {
  const { translate } = useTranslation();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(queryParams.get("page")) || 1;

  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [maxShares, setMaxShares] = useState(0);
  const [modalData, setModalData] = useState({
    sharePrice: 0,
    projectName: "",
    projectId: "",
    returnPerShare: 0,
  });

  useEffect(() => {
    const fetchInvestments = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("userToken");

        if (!token) {
          toast.error("Você precisa estar logado para ver os investimentos");
          return;
        }

        const response = await fetch(
          `https://getusershares-qj6yfdqbiq-uc.a.run.app?page=${currentPage}&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setInvestments(data.shares);
        setTotalPages(data.pagination?.totalPages || 1);
      } catch (error) {
        console.error("Erro ao buscar portfólio:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvestments();
  }, [currentPage]);

  const formatDate = (timestamp) => {
    return new Date(timestamp._seconds * 1000).toLocaleDateString();
  };

  const handleSellClick = (
    maxShares,
    sharePrice,
    projectName,
    projectId,
    returnPerShare
  ) => {
    setMaxShares(maxShares);
    setShowModal(true);
    setModalData({ sharePrice, projectName, projectId, returnPerShare });
  };

  const handleModalSubmit = (data) => {
    console.log("Dados do modal:", data);
  };

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title={translate("portfolio.title")}
        subTitle={translate("portfolio.subtitle")}
        variant="text-left"
        href="/portfolio"
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <table className="cs_cart_table">
                <thead>
                  <tr>
                    <th
                      className="responsive-text"
                      style={{ textAlign: "center" }}
                    >
                      {translate("portfolio.project")}
                    </th>
                    <th
                      className="responsive-text"
                      style={{ textAlign: "center" }}
                    >
                      {translate("portfolio.pricePerShare")}
                    </th>
                    <th
                      className="responsive-text"
                      style={{ textAlign: "center" }}
                    >
                      {translate("portfolio.investedValue")}
                    </th>
                    <th
                      className="responsive-text"
                      style={{ textAlign: "center" }}
                    >
                      {translate("portfolio.numberOfShares")}
                    </th>
                    <th
                      className="responsive-text"
                      style={{ textAlign: "center" }}
                    >
                      {translate("portfolio.lastPurchase")}
                    </th>
                    <th
                      className="responsive-text"
                      style={{ textAlign: "center" }}
                    >
                      {translate("portfolio.shares")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan="6"
                        rowSpan="10"
                        style={{ textAlign: "center", padding: "50px 0" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "570px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LoaderComponent />
                        </div>
                      </td>
                    </tr>
                  ) : investments.length === 0 ? (
                    <tr>
                      <td
                        colSpan="6"
                        style={{
                          textAlign: "center",
                          padding: "50px 0",
                          fontSize: "1.2rem",
                          color: "#666"
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "450px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h3>{translate("portfolio.noShares")}</h3>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    investments.map((investment, index) => (
                      <tr
                        key={investment.projectId}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "transparent" : "#242424",
                        }}
                      >
                        <td
                          className="responsive-text"
                          style={{ textAlign: "center" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {investment.projectTitle}
                            <img
                              src={investment.projectImage}
                              alt={investment.projectName}
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                                marginTop: "10px",
                                borderRadius: "10px",
                                minWidth: "150px",
                                minHeight: "150px",
                                maxWidth: "150px",
                                maxHeight: "150px",
                              }}
                            />
                          </div>
                        </td>
                        <td
                          className="responsive-text"
                          style={{ textAlign: "center", maxWidth: "300px" }}
                        >
                          <div title={investment.sharePrice}>
                            {investment.currency === 'BTC' 
                              ? `₿ ${(parseFloat(investment.sharePrice) / 100000000)}`
                              : investment.currency === 'USDT' 
                              ? `$ ${(investment.sharePrice).toFixed(2)}`
                              : `R$ ${(investment.sharePrice / 100).toFixed(2)}`}
                          </div>
                        </td>
                        <td
                          className="responsive-text"
                          style={{ textAlign: "center" }}
                        >
                          {investment.currency === 'BTC' 
                            ? `₿ ${(parseFloat(investment.sharePrice * investment.numberOfShares || 0) / 100000000)}`
                            : investment.currency === 'USDT' 
                            ? `$ ${((investment.numberOfShares * investment.sharePrice)).toFixed(2)}`
                            : `R$ ${((investment.numberOfShares * investment.sharePrice) / 100).toFixed(2)}`}
                        </td>
                        <td
                          className="responsive-text"
                          style={{ textAlign: "center" }}
                        >
                          {investment.numberOfShares}
                          
                        </td>
                        <td
                          className="responsive-text"
                          style={{ textAlign: "center" }}
                        >
                          {formatDate(investment.createdAt)}
                        </td>
                        <td
                          className="responsive-text"
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            onClick={() =>
                              handleSellClick(
                                investment.numberOfShares,
                                investment.sharePrice,
                                investment.projectTitle,
                                investment.projectId,
                                investment.returnPerShare
                              )
                            }
                          >
                            {translate("portfolio.stake")}
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            {showModal && (
              <StakeShareModal
                close={() => setShowModal(false)}
                onSubmit={handleModalSubmit}
                maxShares={maxShares}
                sharePrice={modalData.sharePrice}
                projectName={modalData.projectName}
                projectId={modalData.projectId}
                returnPerShare={modalData.returnPerShare}
                translate={translate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
